import CustomYaMap from './components/customMap';
import { initScrollTo } from './components/utils';


/* ----------  VARIABLES ---------- */
var lat = coords['lat'];
var lng = coords['lng'];
const mapPoints = [
	{
		point: [lat, lng],
		title: 'Example',
		type: 'general',
		balloonType: 'custom',
	}
];


/* ---------- INIT ---------- */
(function() {
	new CustomYaMap('map', mapPoints);
	$('[data-toggle="datepicker"]').datepicker({
		language: 'ru-RU'
	});

	$('.closeModal').click(function(){
		$.fancybox.close(true);
	});

	$('.showForm').click(function(){
		var { action, title, btn } = $(this).data();
		var _form = $("#order-form .form");
		_form.find('.form__title').text(title);
		_form.find('input[name=action]').val(action);
		_form.find('button[type=submit]').text(btn);
		$.fancybox.open({
			src  : '#order-form',
			type : 'inline',
		});
		$(".ty-modal__text").html(`
			Ваша заявка получена. <br> 
			Наш специалист скоро свяжется с вами
		`);
	});

	$('.showTestimonialsForm').click(function(){
		$.fancybox.open({
			src  : '#testimonials-form',
			type : 'inline',
			opts : {
				afterShow : function( instance, current ) {
				}
			}
		});
		$(".ty-modal__text").html(`
			Ваш отзыв отправлен. <br> 
			Отзыв появится на сайте после прохождения модерации.
		`);
	});

	$(".rate-input").starRating({
		totalStars: 5,
		starShape: 'rounded',
		starSize: 25,
		emptyColor: '#8A8A79',
		hoverColor: 'salmon',
		activeColor: 'crimson',
		useGradient: false,
		callback: function(currentRating, $el){
			$('.form__testimonials').find('input[name=rate]').val(currentRating);
		}
	});
})();




// Event listeners
document.addEventListener('DOMContentLoaded', () => {
	initScrollBehaivor();
	initScrollTo();
	initRoomsGallery();
	initFormValidator();
	initFormHandler();
	initRoomTabs();
	initGallery();
	initTestimonials();
});
window.addEventListener('resize', () => {
});

function showThanksModal() {
	$.fancybox.open({
		src  : '#ty-modal',
		type : 'inline',
	});
}


function checkScrollTop() {
	var scroll = $(window).scrollTop();
	if (scroll >= 100) {
		$(".header").addClass("is-scrolled");
	} else {
		$(".header").removeClass("is-scrolled");
	}
}

function initScrollBehaivor() {
	checkScrollTop();
	$(window).scroll(function() {
		checkScrollTop();  
	});
}


function initTestimonials() {
	$("#testimonials-slider").slick({
		arrows: true,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: false,
		responsive: [
			{
				breakpoint: 900,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 635,
				settings: {
					slidesToShow: 1,
					centerMode: true,
				}
			},
			{
				breakpoint: 420,
				settings: {
					slidesToShow: 1,
					centerMode: false,
				}
			},
		]
	});
}


function showGallery () {
	$.get('/cms/api/singletons/get/general', function(data) {
		var { gallery } = data;
		var items = [];
		gallery.map((item) => {
			items.push({
				src: item['path']
			});
		});
		$.fancybox.open(items);
	});
}


function initGallery() {
	var items = document.querySelectorAll('.gallery-item');
	var moreBtn = document.querySelector('#gallery-count');
	$.get('/cms/api/singletons/get/general', function(data) {
		var { gallery } = data;
		moreBtn.textContent = gallery.length;
		items.forEach((item, index) => {
			let image = `
				<a href="${gallery[index]['path']}" data-fancybox="gallery">
					<img src="${gallery[index]['path']}">
				</a>
			`;
			$(item).html(image);
		});
	});
	$('#showGallery').click(showGallery);
}


function initRoomsGallery() {
	$(".rooms-gallery").slick({
		fade: true,
		arrows: true,
		dots: false,
		adaptiveHeight: true,
		asNavFor: '.rooms-gallery__nav'
	});
	$(".rooms-gallery__nav").slick({
		arrows: false,
		dots: false,
		adaptiveHeight: false,
		asNavFor: '.rooms-gallery',
		slidesToShow: 4,
		slidesToScroll: 1,
		centerMode: false,
		focusOnSelect: true
	});
}



function initFormValidator() {
	$('form').parsley();
}


function initFormHandler() {
	$('form').submit(function() {
		const loader = $(this).parent('.form').find('.form__loader');
		const { action } = $(this).data();

		$.ajax({
			url: action,
			method: 'POST',
			data: $(this).serialize(),
			beforeSend: function() {
				loader.fadeIn(230);
			}
		}).done(() => {
			$.fancybox.close(true);
			showThanksModal();
		}).fail((err) => {
			console.log(err);
		}).always(() => {
			loader.fadeOut(230);
		});
		return false;
	});
}


function initRoomTabs() {
	var tabs = $(".rooms__tabs-item");
	tabs[0].classList.add('is-active');

	var slider = $("#room-tabs").slick({
		fade: true,
		draggable: false,
		arrows: false,
	});

	tabs.click(function(){
		let { tab } = $(this).data();
		$(this).siblings().removeClass('is-active');
		$(this).addClass('is-active');
		$("#room-tabs").slick('slickGoTo', tab);
	});
}